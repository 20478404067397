*, html{
  margin: 0; 
  padding: 0; 
  box-sizing: border-box;
}

.helper{
  background-color: rgb(216, 208, 132);
  height: 100vh;
  display: grid;
  place-items: center;
}

.helper__h1{
  color: white;
  font-size: 90px;
}

.video-wrapper{
  height: 500vh;
  background-color: rgb(189, 236, 209);
}

.video-pinned{
  width: 100%,;
  height: 100vh;
  display: grid;
  place-items: center;
}

.video{
  width: 172.5px;
  height: 128px;
  /* aspect-ratio: 690/512;
  max-width: 690px;
  max-height: 512px; */
  object-fit:cover; 
  border: 3px solid red;
  /* margin-bottom: 600px; */
}

/* .canvas{
  position: absolute;
  margin-top: 600px;
} */